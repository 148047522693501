<!-- 弹框 -->
<template>
  <div class="sum-dialog-container">
    <el-dialog
      v-dialogDrag
      :title="dialogTit"
      :visible.sync="commonSumVisible"
      width="50%"
      :before-close="handleClose"
      append-to-body
    >
      <div>
        <!-- 条件 -->
        <div v-if="paramsTit.length" class="common-screen-container">
          <div class="common-input-container" v-for="(item, i) in paramsTit" :key="i">
            <span>{{ item.name }}:</span>
            <el-input v-if="item.type == 'input'" v-model="headerParams[item.modelKey]" placeholder="请输入内容" class="common-screen-input" clearable ></el-input>
            
            <el-select v-else-if="item.type == 'select'" class="common-screen-input" @change="commonFun()" v-model="headerParams[item.modelKey]" placeholder="请选择" filterable >
              <el-option v-for="item2 in item.selOpt" :key="item2.id" :label="item2.name" :value="item2.id" ></el-option>
            </el-select>
            <el-date-picker v-else-if="item.type == 'date'" class="common-screen-input" @change="commonFun()" v-model="headerParams[item.modelKey]" placeholder="请选择" filterable  type="daterange" range-separator="~" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
              <!-- 所属平台级联 -->
            <el-select v-else-if="item.type == 'platSelect'" class="common-screen-input" @change="commonFun()" v-model="headerParams[item.modelKey]" placeholder="请选择" filterable >
              <el-option v-for="item2 in item.selOpt" :key="item2.id" :label="item2.platformName" :value="item2.id" ></el-option>
            </el-select>

            <el-select v-else-if="item.type == 'customerScreen'" class="common-screen-input" @change="commonFun" v-model="headerParams[item.modelKey]" placeholder="请搜索" clearable filterable remote :remote-method="(val) => {getCustList(val)}">
                <el-option v-for="item in finishedList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>

            <el-cascader v-else-if="item.type == 'departScreen'" @change="commonFun" v-model="headerParams[item.modelKey]" placeholder="请搜索" clearable :props="{ label: 'name', children: 'child', value: 'id', emitPath: false }" class="common-screen-input" :options="selectDepart"></el-cascader>
            <!-- 工单类型名称 -->
            <el-select v-else-if="item.type == 'workSelect'" class="common-screen-input" @change="commonFun" v-model="headerParams[item.modelKey]" placeholder="请选择" clearable filterable>
                <el-option v-for="item in taskTypeSelect" :key="item.id" :label="item.workTypeName" :value="item.id"></el-option>
            </el-select>
          </div>
          <el-button v-show="paramsTit.length" class="common-screen-btn" type="primary" @click=" () => {getHeaderList(headerType)}" >查 询</el-button>
          <el-button v-show="paramsTit.length" class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
        </div>
        <!-- 列表 -->
        <el-table  v-loading="loading" element-loading-text="数据较多，拼命加载中..." :data="headerTableData">
          <el-table-column v-for="(item, i) in childColName" :key="i" :label="item.name + (load ? ' ' : '')" :formatter="tableColumn" :show-overflow-tooltip="true">
            <template slot="header" v-if="item.tableHead">
              <div>
                <span>{{item.name}}</span>
                <br />
                <span class="orange">【{{ pagination[item.tableHead] || 0 }}】</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span v-if="item.colProp && item.colProp.length == 2"
                ><span>{{ DateTransform(scope.row[item.colProp[0]]) }}</span
                ><br />
                <span>{{ DateTransform(scope.row[item.colProp[1]]) }}</span></span
              >
              <span v-else-if="item.colProp && item.colProp[0] == 'price'">
                  <div style="color: #ec9429">{{scope.row.price}}</div>
              </span>
              <span v-else-if="item.colProp && item.colProp[0] == 'failFlag'">
                  <div v-if="scope.row.failFlag == '-1'" style="color: #f09009">待接收</div>
                  <div v-if="scope.row.failFlag == '0'" style="color: #66cc1f">接收成功</div>
                  <div v-if="scope.row.failFlag == '1'" style="color: #f00">拒绝接收</div>
                  <div v-if="scope.row.failFlag == '2'" style="color: #ababab">作废</div>
              </span>
              <span v-else-if="item.colProp && item.colProp[0] == 'shopNames'">
                <span>{{ commonLineFeed(scope.row[item.colProp[0]]) }}</span>
              </span>
              <!-- 工单 -->
              <span v-else-if="item.colProp && item.colProp[0] == 'status'">
                <div v-if="scope.row.status == '1'" class="common-tableStatus-green">进行中</div>
                <div v-if="scope.row.status == '2'" class="common-tableStatus-blue">已完成</div>
                <div v-if="scope.row.status == '3'" class="common-tableStatus-grey">终止</div>
                <div v-if="scope.row.status == 'servicing'" class="common-tableStatus-green">正在服务</div>
                <div v-if="scope.row.status == 'stop'" class="common-tableStatus-red">服务终止</div>
                <div v-if="scope.row.status == 'process'" class="common-tableStatus-red">续签预警</div>
                <div v-if="scope.row.status == 'setFail'" class="common-tableStatus-grey">续签失败</div>
                <div v-if="scope.row.status == 'complete'" class="common-tableStatus-blue">续签成功</div>
                <div v-if="scope.row.status == 'fail'" class="common-tableStatus-orange">续签超时</div>
                <div v-if="scope.row.status == 'setDelete'" class="common-tableStatus-grey">订单作废</div>
                <div v-if="scope.row.status == 'confirmed'" class="common-tableStatus-green">已确认</div>
                <div v-if="scope.row.status == 'unconfirmed'" class="common-tableStatus-red">未确认</div>
                
              </span>
              <span v-else-if="item.colProp && item.colProp[0] == 'priority'">
                <div v-if="scope.row.priority == '1'" class="common-tableStatus-grey">低</div>
                <div v-if="scope.row.priority == '2'" class="common-tableStatus-blue">中</div>
                <div v-if="scope.row.priority == '3'" class="common-tableStatus-orange">高</div>
                <div v-if="scope.row.priority == '4'" class="common-tableStatus-red">紧急</div>
              </span>
              <!-- 工单结束 -->
							<!-- 收款状态--订单中心 -->
							<span v-else-if="item.colProp && item.colProp[0] == 'financialStatus'">
								<div v-if="scope.row.financialStatus == 'pending'">待销售催款</div>
								<div v-if="scope.row.financialStatus == 'confirming'">待财务确认</div>
								<div v-if="scope.row.financialStatus == 'complete'">交易完成</div>
							</span>
							<!-- 收款状态--订单中心 -->
              <!-- 营销--客户中心 -->
              <span v-else-if="item.colProp && item.colProp[0] == 'customerStateName'">
                <div v-if="scope.row.customerState == '1'" class="common-tableStatus-green-bgc">正在服务</div>
                <div v-if="scope.row.customerState == '2'" class="common-tableStatus-blue-bgc">待服务</div>
                <div v-if="scope.row.customerState == '3'" class="common-tableStatus-grey-bgc">服务终止</div>
              </span>
              <!-- 营销--客户中心结束 -->
              <!-- 营销--订单中心 -->
              <span v-else-if="item.colProp && item.colProp[0] == 'paymentMethods'" class="pay">
                <div v-if="scope.row.paymentMethods == '前置收款'">
                  <span class="label1">
                    <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>
                    前置收款
                  </span>
                </div>
                <div v-if="scope.row.paymentMethods == '前置服务'">
                  <span class="label2">
                    <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>前置服务
                  </span>
                </div>
                <div v-if="scope.row.paymentMethods == '免费试用'">
                  <span class="label3">
                    <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>免费试用
                  </span>
                </div>
              </span>
              <!-- 营销--订单中心结束 -->
              <span v-else>
                <span
                  v-if="scope.row[item.colProp[0]] == 'servicing'"
                  class="common-tableStatus-green"
                  >正在服务</span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'stop'"
                  class="common-tableStatus-red"
                  >服务终止</span
                >
                <!-- 订单状态start -->
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'process'"
                  class="common-tableStatus-red"
                  >续签预警</span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'setFail'"
                  class="common-tableStatus-grey"
                  >续签失败</span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'complete'"
                  class="common-tableStatus-blue"
                  >续签成功</span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'fail'"
                  class="common-tableStatus-orange"
                  >续签超时</span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'setDelete'"
                  class="common-tableStatus-grey"
                  >订单作废</span
                >
                <!-- 订单状态end -->
                <!-- 出账类型start -->
                <span
                  v-else-if="item.colProp&&item.colProp[0] == 'expenditureType'"
                  >
                 {{ (option.filter((itemTwo) => { return itemTwo.value == scope.row[item.colProp[0]]}))&&
                    option.filter((itemTwo) => { return itemTwo.value == scope.row[item.colProp[0]]})[0]&&
                    option.filter((itemTwo) => { return itemTwo.value == scope.row[item.colProp[0]]})[0].label }}
                  </span
                >
                <!-- 出账类型 end-->
                <!-- 出账方式 start-->
                <span
                  v-else-if="item.colProp&&item.colProp[0] == 'recordType'"
                  >
                 {{ (option2.filter((itemTwo) => { return itemTwo.value == scope.row[item.colProp[0]]}))&&
                    option2.filter((itemTwo) => { return itemTwo.value == scope.row[item.colProp[0]]})[0]&&
                    option2.filter((itemTwo) => { return itemTwo.value == scope.row[item.colProp[0]]})[0].label }}
                  </span
                >
                <!-- 出账方式 end-->
                <!-- 出账状态 start-->
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'confirmed'"
                  class="common-tableStatus-green"
                  >正常
                  </span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'unconfirmed'"
                  >已作废
                  </span
                >
                <!-- 出账状态 end-->
                <!-- 处理状态 start-->
                <span v-else-if="scope.row[item.colProp[0]] == 'unDeal'">未处理</span>
                <span v-else-if="scope.row[item.colProp[0]] == 'deal'">已处理 </span>
                <!-- 处理状态 end-->
                <span class="table-btn" @click="() => { routerFun(scope.row, item.url, item.paramsArr) }" v-else-if="item.url">{{ scope.row[item.colProp[0]] || "--" }}</span>
                <span v-else>{{ scope.row[item.colProp[0]] || "--" }}</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <br/>
        <el-pagination
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          style="text-align: center"
          :page-sizes="pagination.pageSizes"
          :page-size="headerParams.pageSize"
          :current-page="headerParams.pageNum"
          layout="total, prev, pager, next"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Config, tableColumn, DateTransform, commonLineFeed } from "../../utils/index.js";
import { getCustList, commonDepartAll } from "../../service/common.js"
import { workOrderType } from "../../service/workOrder.js"
export default {
  name: "",
  components: {},
  props: [
    "methodFuc",
    "commonSumVisible",
		"startDate",
		"endDate"
  ],
  data() {
    return {
      Config,
      tableColumn,
      commonLineFeed,
      DateTransform,
      load: false,
      headerParams: {
        pageNum: 1,
        pageSize: 10,
        startEnd: []
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      loading: false,
      headerTableData: [],
      finishedList: [], // 所属客户下拉-可搜索
      selectDepart: [], // 部门下拉
      dialogTit: '汇总',
      childColName: [],
      paramsTit: [],
      headerType: '',
      defCompId: '', // 默认的公司id
      adminId: '',
      option: [], // 出账类型
      option2: [], //收款方式
      date: [], //日期
      proType: "", //项目类型
      taskTypeSelect: [],
      comId:"", //公司id （有些情况公司id判断传不过来）
      workType: "" ,//工单类型（任务||审批）
      changePageNum: "", //工单列表，有的page有的pageNum
      creatFlag: ""
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    routerFun (row, url, paramsArr) { // 弹窗跳转
      let newArr = []
      paramsArr.map((item) => {
        newArr.push(`${item['urlKey']}=${row[item['paramsKey']]}`)
      })
      let routeUrl = url + '?' + newArr.join('&')
      window.open(routeUrl)
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let { data } = await commonDepartAll({}, id);
      this.selectDepart = data ? data : [];
    },
    async getCustList(name) { // 所属客户下拉-搜索
      let resData = (await getCustList({ customerName: name })).data;
      this.finishedList = resData;
    },
    async getAllData(type, name, screen, arr, companyId,adId,option,option2,date,proType,changePageNum,workType,comId,dateType,creatFlag) { 
      // 父组件传值. type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据, companyId :默认选中的公司
      // date:日期    proType：首页项目类型   
      // adminId: 工单人员ID  changePageNum:工单列表，有的page有的pageNum  workType: 工单类型（任务||审批） comId:公司id （有些情况公司id判断传不过来）
      // 【14】dateType: 有日期，日期是checkDate，不是开始结束
      // [15] creatFlag: 订单中心切换显示
        this.headerType = type;
        this.dialogTit = name;
        this.paramsTit = screen;
        this.childColName = arr;
        this.option = option
        this.option2 = option2
        this.date = date //日期
        this.proType = proType //首页项目类型
        this.comId = comId //公司id （有些情况公司id判断传不过来）
        this.workType = workType //工单类型（任务||审批）
        this.changePageNum = changePageNum
        this.creatFlag = creatFlag
        if (adId) {
            this.adminId = adId
            this.headerParams.adminId = adId
        }else{
          this.adminId = ""
          this.headerParams.adminId = ""
        }
        if (companyId || companyId == 0&&companyId !='') {
            this.defCompId = companyId
            this.headerParams.companyId = companyId
            this.commonDepartAll(companyId)
        } else {
            this.defCompId = ''
        }
        if(date&&date.length){
          if (dateType){
            this.headerParams.checkDate = DateTransform(date[0])
          } else {
            this.headerParams.startDate = DateTransform(date[0])
            this.headerParams.endDate = DateTransform(date[1])
          }
          this.headerParams.startEnd = [...date]
        }
        if(proType){
          if(proType == '0'){
          this.headerParams.cusType = '218'
          this.headerParams.projectId = proType
          }else{
          this.headerParams.cusType = '217'
          this.headerParams.projectId = proType
          }
        }
        if(creatFlag||creatFlag == '0'){
          this.headerParams.creatFlag = creatFlag
        }
        
        if(comId){
          let { data } = await workOrderType({workTypeId: workType, pageNum: 1, pageSize: 99999, companyId: comId})
          this.taskTypeSelect = data.list
        }
        this.getHeaderList(type)
    },
    handleClose() {
      this.headerParams = {
            pageNum: 1,
            pageSize: 10,
            startEnd: []
        };
      this.$emit("handleClose");
    },
    commonFun() {
      // this.headerParams.pageSize = 10;
      // this.headerParams.pageNum = 1;
      let headerParams = {...this.headerParams}
      if (headerParams.startEnd && headerParams.startEnd.length) {
        headerParams.startDate = DateTransform(headerParams.startEnd[0])
        headerParams.endDate = DateTransform(headerParams.startEnd[1])
        headerParams.pageSize = 10
        headerParams.pageNum = 1
      }
      this.headerParams = Object.assign({}, headerParams)
      this.$set(this,'headerParams', headerParams)
      this.getHeaderList(this.headerType);
    },
    async getHeaderList(type) {
      this.loading = true;
      if(this.workType){
          this.headerParams.workTypeId = this.workType
      }
      if(this.changePageNum){
        this.headerParams.page = this.headerParams.pageNum
        this.headerParams.pageNum
      }
      let params = { ...this.headerParams };
      if (params.startEnd) {
        delete params.startEnd
      }
      if (type) {
        params.type = type;
      }
			if (this.startDate) {
				params.startDate = this.startDate
			}
			if (this.endDate) {
				params.endDate = this.endDate
			}
      const { data } = await this.methodFuc(params);
      this.load = !this.load
      if (data && data.list && data.list.length) {
        this.headerTableData = data.list;
        this.pagination.total = data.total;
        let childColName = [...this.childColName]
        childColName.map((item) => {
          if (item.tableHead) {
            this.pagination[item.tableHead] = data[item.tableHead] || '0'
          }
        })
      } else if (data && data.records && data.records.length) {
        this.headerTableData = data.records;
        this.pagination.total = data.total;
        let childColName = [...this.childColName]
        childColName.map((item) => {
          if (item.tableHead) {
            this.pagination[item.tableHead] = data[item.tableHead] || '0'
          }
        })
      } else {
        this.headerTableData = [];
        this.pagination.total = 0;
        let childColName = [...this.childColName]
        childColName.map((item) => {
          if (item.tableHead) {
            this.pagination[item.tableHead] = data[item.tableHead] || '0'
          }
        })
        // this.pagination.pageSize = data.size;
      }
      this.loading = false;
    },
    async resetBtn() {
        this.headerParams = {
            pageNum: 1,
            pageSize: 10,
            startEnd: []
        };
        if (this.adminId) {
            this.headerParams.adminId = this.adminId
        }
        if (this.defCompId || this.defCompId == 0) {
            this.headerParams.companyId = this.defCompId
        }
        if(this.date&&this.date.length){ //日期
          this.headerParams.startDate = DateTransform(this.date[0])
          this.headerParams.endDate = DateTransform(this.date[1])
        }
        if(this.proType){ //项目类型
          this.headerParams.cusType = this.proType
        }
        this.getHeaderList(this.headerType);
        if(this.workType){
          let { data } = await workOrderType({workTypeId: this.workType, pageNum: 1, pageSize: 99999, companyId: this.comId})
          this.taskTypeSelect = data.list
        }
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.headerParams.pageSize = e;
      } else {
        this.headerParams.pageNum = e;
      }
      this.getHeaderList(this.headerType);
    },
  },
};
</script>

<style lang="less" scoped>
.table-btn {
  color: #1890ff;
  cursor: pointer;
}
.orange {
  color: #f09009;
}
.sum-dialog-container {
  padding-top: 1px;
}
.label1{
  
  box-sizing: border-box;
  display: inline-block;
  color: #706EFF;
  border: 1px solid #706EFF;
  border-radius: 4px;
  background-color: #f4f4ff;
  font-size: 12px;
  padding: 2px 8px;
}
.label2{
  // height: 33px;
  display: inline-block;
  color: #CE358F;
  border: 1px solid #CE358F;
  border-radius: 4px;
  background-color: #fbeff6;
  font-size: 12px;
  padding: 2px 8px;
}
.label3{
  // height: 33px;
  display: inline-block;
  color: #F09009;
  border: 1px solid #F09009;
  border-radius: 4px;
  background-color: #fef6ec;
  font-size: 12px;
  padding: 2px 8px;
}
.pay{
  display: inline-block;
  width: 110px !important;
}
// .td{
//   &
// }
.el-table_3_column_46  {
  width: 110px !important;
}
</style>
